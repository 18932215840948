<template>
    <div class="card">
        <div v-if="!item.url">
            <div class="d-flex justify-content-end px-1 py-1 text-right">
                <select @change="$emit('onDateChange', item)" v-model="item.dateRange" name="" id="" class="form-control width-150" style="padding: 0; border:none; text-align: right">
                    <option value="" disabled selected>Select Range</option>
                    <option v-for="(range, j) in timeRange" :key="j" :value="range.value">{{ range.name }}</option>
                </select>
            </div>
            <hr style="margin:0">
            <div  class="card-body d-flex justify-content-between align-items-center">
                <div class="truncate">
                    <h2 style="font-size: 24px" class="mb-25 font-weight-bolder text-left"> {{ commaFormat(item.value) }} </h2>
                    <p style="font-size: 14px" class="qcont mb-0 text-left"> {{ item.name }} </p>
                </div>
                <span class="b-avatar badge-light-primary rounded-circle" style="width: 45px; height: 45px;">
                    <span class="b-avatar-custom">
                        <img width="35" :src="`/app-assets/images/icons/dashboard/${item.image}.png`" alt="">
                    </span>
                </span>
            </div>
        </div>
        <div v-else>
            <div class="px-1 py-1">
                <div class="d-flex justify-content-end">
                    <span @click="navigate(item.url)" class="cursor-pointer">See All</span> 
                </div>
            </div>
            <hr style="margin: 0">
            <div @click="navigate(item.url)"  class="card-body d-flex justify-content-between align-items-center cursor-pointer">
            
            <div class="truncate">
            <h2 style="font-size: 24px" class="mb-25 font-weight-bolder text-left"> {{ commaFormat(item.value) }} </h2>
            <p style="font-size: 14px" class="qcont mb-0 text-left"> {{ item.name }} </p>
            </div>
            <span class="b-avatar badge-light-primary rounded-circle" style="width: 45px; height: 45px;">
                <span class="b-avatar-custom">
                    <img width="35" :src="`/app-assets/images/icons/dashboard/${item.image}.png`" alt="">
                </span>
            </span>
        </div>
        </div>
        
    </div>
</template>

<script setup>
import {useRoute, useRouter} from 'vue-router'
import figureFormatter from '@/services/utils/figureFormatter'
import useDate from '@/services/utils/day'
const {
    firstDateOfMonth,
    lastDateOfMonth
} = useDate()
const router = useRouter()
const route = useRoute()
const {commaFormat} = figureFormatter ()
defineProps({
  item: Object,
  timeRange: Array
})
function navigate(url) {
    router.push({name: url, params: route.params, query: {start: firstDateOfMonth, end: lastDateOfMonth} })
}
</script>

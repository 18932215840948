<template>
  <div style="margin: 0 auto;">
    <div class="row text-center">
      <template v-for="(item, i) in statisticsItem" :key="i">
          <div class="col-sm-6 col-lg-4">
            <StatisticsCard
              @onDateChange="onDateChange"
              :item="item"
              :timeRange="timeRange"
            />
          </div>
      </template>
    </div>

      <div  class="row">
        <div class="col-md-8">
          <div class="card p-1">
            <div style="height: 23px">
              <span>Receipt & Payment</span>
            </div>
            <hr>
            <!-- <div class="d-flex justify-content-end">
              <button class="btn btn-text">See all</button>
              <select class="form-control width-200" name="" id="">
                <option value="">This month</option>
              </select>
            </div> -->
            <BarChart
              :chartData="{
                labels: [
                  'January',
                  'February',
                  'March',
                  'April',
                  'May',
                  'June',
                  'July',
                  'August',
                  'September',
                  'October',
                  'November',
                  'December'
                ],
                datasets: receipt_and_payment_data.data
              }"
            
            />
          </div>
        </div>
        <div class="col-md-4">
          <div class="card p-1">
            <div class="d-flex justify-content-between">
              <span>Sales</span>
                <select @change="fetchPieData" v-model="salesData.dateRange" name="" id="" class="form-control width-150" style="padding: 0; border:none; text-align: right">
                    <option value="" disabled selected>Select Range</option>
                    <option v-for="(range, j) in timeRange" :key="j" :value="range.value">{{ range.name }}</option>
                </select>
            </div>
            
            <hr>
            <p class="text-center center-position" v-if="salesData.data?.labels?.length == 0">Not data available</p>
            <PieChart
              :chartData="salesData.data"
            />
          </div>
        </div>
      </div>
      <GlobalLoader />
    </div>
</template>

<script>
import BarChart from '@/components/atom/chart/barChart'
import PieChart from '@/components/atom/chart/pieChart'
import handleCompany from '@/services/modules/company'
import StatisticsCard from '@/components/atom/StatisticsCard'
import {useStatistics} from '@/services/modules/dashboard/statistics.js'
import { inject } from 'vue'
import { useRoute } from 'vue-router'

export default {
  name: 'CompanyDashboard',

  components: {
    BarChart,
    StatisticsCard,
    PieChart
  },

  computed: {
    dashboardStatistics() {
      return this.makeStatistics(this.dashboard, ['receipt_and_payment', 'sales'])
    }
  },

  data: () => ({
    dashboard: {}
  }),

  methods: {
    onDateChange(item) {
      let query = `&${item.key}={${item.dateRange}}`
      this.getDashboardData(query)
    },
    fetchPieData() {
      let query = `&${this.salesData.key}={${this.salesData.dateRange}}`
      this.getDashboardData(query)
    }
  },

  mounted() {
    this.getDashboardData()
  },

  setup() {
    const showError =  inject('showError');
    const showSuccess =  inject('showSuccess');
    const route = useRoute()
    const params = route.params

    const {
      getDashboard,
      makeStatistics,
    } = handleCompany()

    const {
      timeRange,
      getDashboardData,
      receipt_and_payment_data,
      salesData,
      statisticsItem
    } = useStatistics(params)

    return {
      getDashboard,
      makeStatistics,
      showError,
      showSuccess,
      timeRange,
      getDashboardData,
      receipt_and_payment_data,
      salesData,
      statisticsItem

    }
  }

}
</script>

<style>
.center-position {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
